import React from 'react';
import logo from '../Assets/Torque-Logo1.png';

const Footer = () => {
  return (
    <footer className="bg-gradient-to-b from-black to-[#A0CF3B] mt-6">
      <div className="mx-auto max-w-5xl px-4 py-8 sm:px-6 lg:px-8">
        <div className='w-full flex justify-center items-center'>
          <img src={logo} alt="Torque Entertainment Logo" className='h-24' />
        </div>

        <p className="mx-auto mt-6 max-w-md text-center text-white text-lg">
          Experience the Unexpected with us!
        </p>

        <ul className="mt-6 flex flex-wrap justify-center gap-10 uppercase font-bold md:gap-8 lg:gap-12">
          <li>
            <a className=" transition hover:text-gray-50" href="#home">Home</a>
          </li>
          <li>
            <a className="transition hover:text-gray-50" href="#about">About</a>
          </li>
          <li>
            <a className="transition hover:text-gray-50" href="#services">Services</a>
          </li>
          <li>
            <a className="transition hover:text-gray-50" href="#gallery">Gallery</a>
          </li>
          <li>
            <a className="transition hover:text-gray-50" href="#contact">Contact</a>
          </li>
        </ul>

        <p className="mx-auto mt-6 max-w-sm text-center">
          © {new Date().getFullYear()} Torque Entertainment. All rights reserved. <br /> Designed by <a href="https://namuvi.com/" className='underline'>Namuvi Technologies</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
