import React, { useState, useEffect } from "react";
import Img1 from "../Assets/images/banner/01.jpg";
import Img2 from "../Assets/images/banner/02.jpg";
import Img3 from "../Assets/images/banner/03.jpg";
import Img4 from "../Assets/images/banner/04.jpg";

const images = [Img1, Img2, Img3, Img4];

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  // Detect screen size and check if it's mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Set to true if screen width is less than 768px (Tailwind's "md" breakpoint)
    };

    handleResize(); // Run on mount to check initial screen size
    window.addEventListener("resize", handleResize); // Add event listener for resize

    return () => window.removeEventListener("resize", handleResize); // Cleanup on unmount
  }, []);

  // Carousel auto-slide effect
  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(slideInterval);
  }, []);

  return (
    <div className="relative w-full h-screen overflow-hidden home">
      {isMobile && (
        <div className="text-center py-16 mt-12 text-white">
          <h1 className="text-4xl font-bold">Welcome <br />to <br /> <span className="text-[#A0CF3B]">Torque Entertainment</span></h1>
          <p className="text-lg mt-2">Experience the unexpected with us!</p>
        </div>
      )}
      <div
        className="flex transition-transform duration-700"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {images.map((image, index) => (
          <div key={index} className="w-full flex-shrink-0 py-0 md:py-20 p-2 md:p-0">
            <img
              src={image}
              alt={`Slide ${index}`}
              className="w-full h-full object-cover rounded-xl md:rounded"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
