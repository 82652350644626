import React, { useRef, useEffect } from "react";
import { useMotionValue, motion, useSpring, useTransform } from "framer-motion";
import virtualImg from "../Assets/images/services/Virtual.jpg";
import hybridImg from "../Assets/images/services/Hybrid.jpg";
import spaceImg from "../Assets/images/services/Space.jpg";
import { animateContentBlocks } from "../utils/whatwecan-animation";

const WhatweCan = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    animateContentBlocks();
  }, []);

  const ContentBlock = ({ heading, imgSrc, subheading, reverse = false, gradientColors }) => {
    const ref = useRef(null);

    const x = useMotionValue(0);
    const y = useMotionValue(0);

    const mouseXSpring = useSpring(x);
    const mouseYSpring = useSpring(y);

    const top = useTransform(mouseYSpring, [0.5, -0.5], ["40%", "60%"]);
    const left = useTransform(mouseXSpring, [0.5, -0.5], ["60%", "70%"]);

    const handleMouseMove = (e) => {
      const rect = ref.current.getBoundingClientRect();
      const width = rect.width;
      const height = rect.height;
      const mouseX = e.clientX - rect.left;
      const mouseY = e.clientY - rect.top;
      const xPct = mouseX / width - 0.5;
      const yPct = mouseY / height - 0.5;
      x.set(xPct);
      y.set(yPct);
    };

    return (
      <motion.div
        ref={ref}
        onMouseMove={handleMouseMove}
        initial="initial"
        whileHover="whileHover"
        className={`content-block group relative flex flex-col md:flex-row ${
          reverse ? "md:flex-row-reverse" : "md:flex-row"
        } items-center justify-between py-8 px-4 md:px-8 -mt-[100px] md:mt-0`}
      >
        <div className="py-4 md:py-8">
          <span
            className="relative z-10 mt-4 block text-3xl md:text-4xl font-bold bg-clip-text text-transparent"
            style={{
              backgroundImage: `linear-gradient(to right, ${gradientColors.join(", ")})`,
            }}
          >
            {heading}
          </span>
          <span className="relative z-10 mt-2 block text-lg md:text-xl text-neutral-600 transition-colors duration-300 group-hover:text-neutral-50">
            {subheading}
          </span>
        </div>

        <motion.img
          style={{
            top,
            left,
            translateX: "10%",
            translateY: "-20%",
          }}
          variants={{
            initial: { scale: 0, rotate: "-12.5deg" },
            whileHover: { scale: 1, rotate: "12.5deg" },
          }}
          transition={{ type: "spring" }}
          src={imgSrc}
          className="absolute md:static z-0 h-40 w-40 md:h-48 md:w-64 rounded-lg object-cover"
          alt=""
        />
      </motion.div>
    );
  };

  const gradientColorsList = [
    ["#8E44AD", "#F5EEF7"], 
    ["#F39C12", "#FDF9E7"], 
    ["#4498DB", "#33FFFF"]  
  ];

  return (
    <section ref={sectionRef} className="py-16 md:py-32">
      <div className="mx-auto max-w-3xl md:max-w-5xl space-y-12 md:space-y-16">
        <ContentBlock
          heading="We have everything!"
          subheading="Driving the dagger to the perfect centre we ripple through every direction to bring solutions to your event requirements."
          imgSrc={virtualImg}
          gradientColors={gradientColorsList[0]}
        />
        <ContentBlock
          heading="We Know Everyone!"
          subheading="Our contact book of event management is pretty huge. We know the market and can make a few calls to get things done right on time."
          imgSrc={hybridImg}
          reverse
          gradientColors={gradientColorsList[1]}
        />
        <ContentBlock
          heading="We create every way!"
          subheading="We own Doremon’s magic pocket so all you gotta do is bring us your objectives and we will bring a lot of breakthrough ideas. Strictly not usual."
          imgSrc={spaceImg}
          gradientColors={gradientColorsList[2]}
        />
      </div>
    </section>
  );
};

export default WhatweCan;
