import React from "react";
import Navbar from "./Components/Navbar";
import Services from "./Components/Services";
import About from "./Components/About";
import Home from "./Components/Home";
import WhatweCan from "./Components/WhatweCan";
import Footer from "./Components/Footer";
import Gallery from "./Components/Gallery";
import Contact from "./Components/Contact";
import Events from "./Components/Events";

function App() {
  return (
    <div>
      <Navbar />
      <Home />
      <WhatweCan />
      <About />
      <Services />
      <Events/>
      <Gallery />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
