import { useAnimate } from "framer-motion";
import React, { useRef } from "react";

import img1 from "../Assets/images/gallery/img-1.jpg"
import img2 from "../Assets/images/gallery/img-2.jpg"
import img3 from "../Assets/images/gallery/img-3.jpg"
import img4 from "../Assets/images/gallery/img-4.jpg"
import img5 from "../Assets/images/gallery/img-5.jpg"
import img6 from "../Assets/images/gallery/img-6.jpg"
import img7 from "../Assets/images/gallery/img-7.jpg"
import img8 from "../Assets/images/gallery/img-8.jpg"
import img9 from "../Assets/images/gallery/img-9.jpg"
import img10 from "../Assets/images/gallery/img-10.jpg"
import img11 from "../Assets/images/gallery/img-11.jpg"
import img12 from "../Assets/images/gallery/img-12.jpg"
import img13 from "../Assets/images/gallery/img-13.jpg"
import img14 from "../Assets/images/gallery/img-14.jpg"
import img15 from "../Assets/images/gallery/img-15.jpg"
import img16 from "../Assets/images/gallery/img-16.jpg"
import img17 from "../Assets/images/gallery/img-17.jpg"
import img18 from "../Assets/images/gallery/img-18.jpg"
import img19 from "../Assets/images/gallery/img-19.jpg"

const Example = () => {
  return (
    <MouseImageTrail
      renderImageBuffer={50}
      rotationRange={25}
      images={[
       img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19
      ]}
    >
      <section className=" h-screen w-full place-content-center gallery ">
        <p className="text-center text-4xl font-bold uppercase text-[#A0CF3B]">Gallery</p>
        <p className="text-center text-sm text-white md:hidden block">(Tap anywhere to see our <span className="text-[#A0CF3B]">Magic)</span></p>
      </section>
    </MouseImageTrail>
  );
};

export default Example

const MouseImageTrail = ({
  children,
  images,
  renderImageBuffer,
  rotationRange,
}) => {
  const [scope, animate] = useAnimate();

  const lastRenderPosition = useRef({ x: 0, y: 0 });
  const imageRenderCount = useRef(0);

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;

    const distance = calculateDistance(
      clientX,
      clientY,
      lastRenderPosition.current.x,
      lastRenderPosition.current.y
    );

    if (distance >= renderImageBuffer) {
      lastRenderPosition.current.x = clientX;
      lastRenderPosition.current.y = clientY;

      renderNextImage();
    }
  };

  const calculateDistance = (x1, y1, x2, y2) => {
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;

    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

    return distance;
  };

  const renderNextImage = () => {
    const imageIndex = imageRenderCount.current % images.length;
    const selector = `[data-mouse-move-index="${imageIndex}"]`;

    const el = document.querySelector(selector);

    el.style.top = `${lastRenderPosition.current.y}px`;
    el.style.left = `${lastRenderPosition.current.x}px`;
    el.style.zIndex = imageRenderCount.current.toString();

    const rotation = Math.random() * rotationRange;

    animate(
      selector,
      {
        opacity: [0, 1],
        transform: [
          `translate(-50%, -25%) scale(0.5) ${
            imageIndex % 2
              ? `rotate(${rotation}deg)`
              : `rotate(-${rotation}deg)`
          }`,
          `translate(-50%, -50%) scale(1) ${
            imageIndex % 2
              ? `rotate(-${rotation}deg)`
              : `rotate(${rotation}deg)`
          }`,
        ],
      },
      { type: "spring", damping: 15, stiffness: 200 }
    );

    animate(
      selector,
      {
        opacity: [1, 0],
      },
      { ease: "linear", duration: 0.5, delay: 5 }
    );

    imageRenderCount.current = imageRenderCount.current + 1;
  };

  return (
    <div
      ref={scope}
      className="relative overflow-hidden"
      onMouseMove={handleMouseMove}
    >
      {children}

      {images.map((img, index) => (
        <img
          className="pointer-events-none absolute left-0 top-0 h-52 w-60 rounded-xl border-2 border-black bg-neutral-900 object-cover opacity-0"
          src={img}
          alt={`Mouse move image ${index}`}
          key={index}
          data-mouse-move-index={index}
        />
      ))}
    </div>
  );
};