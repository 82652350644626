import React, { useState } from 'react';
import desktopPoster from '../Assets/images/banner/02.jpg'; // Desktop poster
import mobilePoster from '../Assets/images/gallery/img-5.jpg'; // Mobile poster
import promoVid from '../Assets/videos/promoVid.mp4';
import InkSplatter from './InkSplatter';

const Events = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = () => {
    setIsPlaying(true);
  };

  return (
    <section className='h-screen relative flex items-center overflow-hidden'>
      <InkSplatter/>
      <div className='absolute inset-0 z-0'>
        <div className='text-center'>
          <h1 className='text-4xl font-bold text-white py-2'>
            Upcoming <span className='text-[#A0CF3B]'>Events</span>
          </h1>
        </div>
        <div className="relative max-w-[1200px] mx-auto py-8">
          {!isPlaying ? (
            <div>
              {/* Desktop and mobile posters */}
              <picture>
                <source media="(max-width: 767px)" srcSet={mobilePoster} className='p-4' />
                <img src={desktopPoster} alt="Video poster" className="w-full h-auto rounded-xl" />
              </picture>
              
              <button
                onClick={handlePlayClick}
                className="absolute inset-0 flex items-center justify-center cursor-pointer"
                aria-label="Play video"
              >
                <div className="bg-black rounded-full p-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    className="w-12 h-12"
                  >
                    <path d="M8 5v14l11-7z" />
                  </svg>
                </div>
              </button>
            </div>
          ) : (
            <video src={promoVid} autoPlay controls className="w-full h-auto rounded-xl"></video>
          )}
        </div>
      </div>
    </section>
  );
};

export default Events;
